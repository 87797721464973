import { ReloadOutlined, ToolOutlined } from "@ant-design/icons";
import AppCustomDrawerWrapper from "@uni/core/AppCustomDrawerWraper";
import AppsContainer from "@uni/core/AppsContainer";
import AppsContent from "@uni/core/AppsContainer/AppsContent";
import AppsHeader from "@uni/core/AppsContainer/AppsHeader";
import AppTableContainer from "@uni/core/AppTableContainer";
import { URL_API } from "@uni/services/endpoint";
import { Pagination } from "antd";
import useGetEcusInvoice from "hooks/apis/ecus/useGetInvoice";
import useAppShowHideTableColumn from "hooks/UseAppShowHideTableColumn";
import EcusTableSearch from "pages/data/Exim/Ecus/EcusTableSearch";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { LimitDefault, OffsetDefault } from "shared/constants/AppConst";
import AppButtonActions from "@uni/core/AppButtonAction";
import useGetDetermineColumn from "./useGetDetermineColumn";
import "./index.style.less";
import AppCard from "@uni/core/AppCard";
import AppPageMetadata from "@uni/core/AppPageMetadata";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import useGetViewDetermineFieldConfig from "./ViewDetermineModal/useGetViewDetermineByNo";
import useGetInvoiceByInvoiceNo from "hooks/apis/ecus/useGetInvoiceByInvoiceNo";
import AppCustomModalWrapper from "@uni/core/AppCustomModalWrapper";
import GenerateModal from "./GenerateModal";
import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";

const DETERMINE_TABLE_KEY = "DetermineTable";
const Determine = () => {
  const viewGenerateModalRef = useRef();

  const { companyManagement } = useLayoutContext();
  const { messages } = useIntl();
  const [selectedRowIds, setSelectedRowIds] = useState();
  const [selectedGoodsRowIds, setSelectedGoodsRowIds] = useState([]);
  const queryClient = useQueryClient();

  const determineColumns = useGetDetermineColumn();

  const viewDetermineColumns = useGetViewDetermineFieldConfig();
  const [params, setParams] = useState({
    limit: LimitDefault,
    offset: OffsetDefault,
    search: "",
  });

  const { data, isFetching } = useGetEcusInvoice({
    ...params,
    folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
    company_slug: companyManagement?.company?.detail?.company?.slug || "",
  });
  const { filteredTableColumns, tableColumnDisplayConfigurator } =
    useAppShowHideTableColumn({
      tableKey: DETERMINE_TABLE_KEY,
      tableColumns: determineColumns,
    });

  const { data: dataDetail, isLoading } = useGetInvoiceByInvoiceNo({
    invoice_no: selectedRowIds?.[0],
    folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
    company_slug: companyManagement?.company?.detail?.company?.slug || "",
  });

  const [columnsState, setColumnsState] = useState(() =>
    viewDetermineColumns?.map((column, i) => ({
      ...column,
      key: `${i}`,
      onHeaderCell: () => ({
        id: `${i}`,
        draggable: column.draggable !== false,
      }),
      onCell: () => ({
        id: `${i}`,
      }),
    }))
  );

  const { setDataRow, dataRow } = useGenerateContext();
  return (
    <>
      <AppPageMetadata title={messages["sidebar.determination.determine"]} />
      <AppCard
        title={messages["sidebar.determination.determine"]}
        className="single-tab-card-header"
      >
        <div className="table-container">
          <div className="choosing-table">
            <AppsContainer type="bottom" fullView>
              <AppsHeader>
                <div className="page-header">
                  <EcusTableSearch
                    onValuesChange={(_changedValues, allValues) => {
                      setParams((prev) => {
                        return {
                          ...prev,
                          ...allValues,
                          offset: OffsetDefault,
                        };
                      });
                    }}
                  />

                  <AppButtonActions
                    // scopes={[PERMISSIONS.CREATE_TAG]}
                    icon={<ReloadOutlined />}
                    title={messages["common.refresh"]?.toString()}
                    type="text"
                    onClick={() =>
                      queryClient.invalidateQueries(
                        URL_API.ECUS.GET_ALL_INVOICE
                      )
                    }
                  />
                </div>
              </AppsHeader>
              <AppsContent
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <AppTableContainer
                  loading={isFetching}
                  data={data?.results}
                  columnsState={filteredTableColumns}
                  setColumnsState={() => {}}
                  columns={filteredTableColumns}
                  rowClassName={(record) => {
                    return record.invoice_no === selectedRowIds?.[0]
                      ? "table-row-selected"
                      : "";
                  }}
                  rowKey="invoice_no"
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        setSelectedRowIds([record.invoice_no]);
                      },
                    };
                  }}
                  style={{ cursor: "pointer", height: "100%" }}
                  scroll={{ x: "max-content", y: "calc(100vh - 270px)" }}
                />
              </AppsContent>
              <div className="custom-pagination">
                <Pagination
                  defaultPageSize={20}
                  current={Math.ceil(params.offset / params.limit) + 1}
                  onChange={(page, pageSize) => {
                    setParams((prev) => {
                      return {
                        ...prev,
                        limit: pageSize,
                        offset: (page - 1) * pageSize,
                      };
                    });
                  }}
                  total={data?.count}
                  size="small"
                />
              </div>
            </AppsContainer>
          </div>
          <div className="result-table">
            <AppsContainer type="bottom" fullView>
              <AppsHeader>
                <div className="page-header-right">
                  <AppButtonActions
                    icon={<ToolOutlined />}
                    title={messages["common.generate"]?.toString()}
                    type="primary"
                    disabled={!dataRow?.length}
                    onClick={() => viewGenerateModalRef?.current?.openModal()}
                  />
                </div>
              </AppsHeader>
              <AppsContent
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <AppTableContainer
                  loading={isLoading}
                  data={dataDetail?.results}
                  columns={viewDetermineColumns}
                  columnsState={columnsState}
                  setColumnsState={setColumnsState}
                  rowSelection={{
                    selectedRowKeys: selectedGoodsRowIds,
                    type: "checkbox",
                    onChange: (newSelectedRowKeys, selectedRows) => {
                      const updatedRows = selectedRows.map((row) => ({
                        ...row,
                        insertType: "formula",
                        // form_type: "CTC",
                      }));
                      setDataRow(updatedRows);
                      setSelectedGoodsRowIds(newSelectedRowKeys);
                    },

                    getCheckboxProps: (record) => ({
                      disabled: record?.is_run_bangke, // Disable checkbox if 'is run bangke'
                    }),
                  }}
                  className="table-container-information"
                  scroll={{ x: "max-content", y: "calc(100vh - 270px)" }}
                />
              </AppsContent>
            </AppsContainer>
          </div>
        </div>
      </AppCard>
      <GenerateModal
        ref={viewGenerateModalRef}
        setSelectedGoodsRowIds={setSelectedGoodsRowIds}
      />
    </>
  );
};

export default Determine;

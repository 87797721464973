import { HotTable } from "@handsontable/react";
import { convertDateUni, getNumberAfterRVC } from "@uni/utility/Utils";
import { registerAllModules } from "handsontable/registry";
import { useCallback, useEffect, useMemo, useRef } from "react";

registerAllModules();
const transformData = (data) => {
  return data?.map((item, index) => [
    index + 1,
    item.item_name,
    item.hs_code,
    item.unit,
    item.bom_value?.toFixed(3),
    item.unit_price.toFixed(3),
    item.origin_price === 0 ? "" : item.origin_price.toFixed(3),
    item.non_origin_price === 0 ? "" : item.non_origin_price.toFixed(3),
    item.from_country,
    item.cd_number,
    item.cd_date,
    item.exporter,
    item.issued_date,
  ]);
};

const BangkeRVCDirect = ({ bangkeData, styleTable = null, deminimisData }) => {
  const {
    bangke_data,
    recalculate_args,
    company_info,
    ecus_input,
    start_date,
    message_error,
  } = bangkeData || {};

  const itemMap = useMemo(() => {
    return bangke_data?.length > 0 ? transformData(bangke_data) : [];
  }, [bangke_data]);
  const itemAlight =
    bangke_data?.length > 0
      ? bangke_data?.flatMap((item, index) => [
          { row: 15 + index, col: 0, className: "htCenter htMiddle" },
          { row: 15 + index, col: 2, className: "htCenter htMiddle" },
          { row: 15 + index, col: 3, className: "htCenter htMiddle" },
          { row: 15 + index, col: 4, className: "htCenter htMiddle" },
          { row: 15 + index, col: 5, className: "htCenter htMiddle" },
          { row: 15 + index, col: 6, className: "htCenter htMiddle" },
          { row: 15 + index, col: 7, className: "htCenter htMiddle" },
          { row: 15 + index, col: 8, className: "htCenter htMiddle" },
          { row: 15 + index, col: 9, className: "htCenter htMiddle" },
          { row: 15 + index, col: 10, className: "htCenter htMiddle" },
          { row: 15 + index, col: 11, className: "htCenter htMiddle" },
          { row: 15 + index, col: 12, className: "htCenter htMiddle" },
        ])
      : [];

  const mergeFormular = useCallback(
    (form_cateria, form_type, sedo) => {
      if (form_cateria === "RVC" && ecus_input?.is_direct_rvc) {
        return [
          {
            row: 14 + bangke_data?.length + 10,
            col: 0,
            rowspan: 2,
            colspan: 1,
          },
          {
            row: 14 + bangke_data?.length + 13,
            col: 0,
            rowspan: 2,
            colspan: 1,
          },
          {
            row: 14 + bangke_data?.length + 15,
            col: 0,
            rowspan: 2,
            colspan: 1,
          },
          {
            row: 14 + bangke_data?.length + 18,
            col: 0,
            rowspan: 2,
            colspan: 1,
          },
          {
            row: 14 + bangke_data?.length + 19,
            col: 1,
            rowspan: 1,
            colspan: 9,
          },
        ];
      } else return [];
    },
    [bangke_data?.length]
  );
  const alighFormular = (form_cateria, form_type, sedo) => {
    if (form_cateria === "RVC" && ecus_input?.is_direct_rvc) {
      return [
        {
          row: 14 + bangke_data?.length + 2,
          col: 0,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 2,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 3,
          col: 0,
          className: "htEnd htMiddle",
        },
        {
          row: 14 + bangke_data?.length + 4,
          col: 0,
          className: "htEnd htMiddle",
        },
        {
          row: 14 + bangke_data?.length + 6,
          col: 0,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 6,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 7,
          col: 0,
          className: "htEnd htMiddle",
        },
        {
          row: 14 + bangke_data?.length + 8,
          col: 0,
          className: "htEnd htMiddle",
        },
        {
          row: 14 + bangke_data?.length + 10,
          col: 0,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 10,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 11,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 12,
          col: 0,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 12,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 13,
          col: 0,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 13,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 14,
          col: 1,
          className: " htBold",
        },

        {
          row: 14 + bangke_data?.length + 15,
          col: 0,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 15,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 16,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 17,
          col: 0,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 17,
          col: 1,
          className: " htBold",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 0,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 19,
          col: 1,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 2,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 3,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 4,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 5,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 6,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 7,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 8,
          className: " htMiddle htCenter",
        },
        {
          row: 14 + bangke_data?.length + 18,
          col: 9,
          className: " htMiddle htCenter",
        },
      ];
    } else return [];
  };
  const formular = useCallback(
    (form_cateria, form_type, sedo) => {
      if (form_cateria === "RVC" && ecus_input?.is_direct_rvc) {
        return [
          [
            "Công thức tính RVC trực tiếp =",
            "Chi phí nguyên liệu FTA (Trị giá CIF nguyên liệu, phụ tùng hoặc hàng hóa có xuất xứ FTA do người sản xuất mua hoặc tự sản xuất)",
            "+",
            "Chi phí nhân công trực tiếp",
            "+",
            "Chi phí phân bổ trực tiếp",
            "+",
            "Các chi phí khác",
            "+",
            "Lợi nhuận",
          ],
          ["", "Trị giá FOB", "", "", "", "", "", "", "", "", ""],
        ];
      } else return [];
    },
    [
      ecus_input?.ecus_total_quantity,
      ecus_input?.formula_result,
      recalculate_args?.progress_price,
      recalculate_args?.total_non_origin_price,
      recalculate_args?.total_origin_price,
    ]
  );

  const customBorders = useCallback(
    (form_cateria, form_type, sedo) => {
      if (form_cateria === "RVC" && ecus_input?.is_direct_rvc) {
        return [
          {
            row: 14 + bangke_data?.length + 19,
            col: 1,
            top: { width: 1, color: "black" },
          },
        ];
      } else return [];
    },
    [bangke_data?.length, deminimisData?.is_deminimis]
  );

  const data = useMemo(
    () => [
      ["PHỤ LỤC VIII", "", "", "", "", "", "", "", "", "", "", "", ""],
      [
        `BẢNG KÊ KHAI HÀNG HOÁ XUẤT KHẨU ĐẠT TIÊU CHÍ ${
          recalculate_args?.form_criteria || "CTC"
        }`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "(ban hành kèm theo Thông tư số: 05/2018/TT-BTC ngày 03 tháng 04 năm 2018 quy định về xuất xứ hàng hóa)",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "Tên thương nhân: ",
        company_info?.company_name,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Tiêu chí áp dụng: ",
        recalculate_args?.criteria_value,
        "",
        "",
      ],
      [
        "Địa chỉ: ",
        company_info?.company_address,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Tên hàng hoá: ",
        ecus_input?.ecus_item_name,
        "",
        "",
      ],
      [
        "Mã số thuế: ",
        company_info?.company_tax,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Mã HS của hàng hoá: ",
        ecus_input?.ecus_hs,
        "",
        "",
      ],
      [
        "Tờ khai số: ",
        `${ecus_input?.ecus_cd_number} ngày ${convertDateUni(
          ecus_input?.ecus_cd_date
        )}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Số lượng: ",
        ecus_input?.ecus_total_quantity,
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Trị giá: ",
        Number(ecus_input?.formula_nt_value)?.toLocaleString(),
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        recalculate_args?.progress_price &&
        recalculate_args?.progress_price !== null
          ? "Giá gia công: "
          : "",
        recalculate_args?.progress_price &&
        recalculate_args?.progress_price !== null
          ? Number(recalculate_args?.progress_price)
          : "",
        "",
        "",
      ],
      [],
      [
        "STT",
        "Nguyên phụ liệu",
        "Mã HS",
        "Đơn vị tính",
        "ĐM/1SP",
        "Nhu cầu nguyên liệu sử dụng cho lô hàng",
        "",
        "",
        "Nước xuất xứ",
        "Tờ khai hải quan nhập khẩu/ Hoá đơn giá trị gia tăng",
        "",
        "C/O ưu đãi nhập khẩu/ Bản khai báo của nhà sản xuất/nhà cung cấp nguyên liệu trong nước",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "Đơn giá (CIF)",
        "Trị giá USD",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "Có xuất xứ FTA",
        "Không xuất xứ FTA",
        "",
        "Số",
        "Ngày",
        "Số",
        "Ngày",
      ],
      [
        "(1)",
        "(2)",
        "(3)",
        "(4)",
        "(5)",
        "(6)",
        "(7)",
        "(8)",
        "(9)",
        "(10)",
        "(11)",
        "(12)",
        "(13)",
      ],
      [
        "I",
        "Chi phí nguyên vật liệu:",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      ...itemMap,
      [
        "",
        "Tổng I.",
        "",
        "",
        "",
        "",
        recalculate_args?.total_origin_price?.toFixed(3),
        recalculate_args?.total_non_origin_price?.toFixed(3),
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "II",
        "Chi phí nhân công trực tiếp:",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "1",
        "Lương, thưởng",
        "",
        "",
        "",
        "",
        Number(
          ecus_input?.direct_rvc_calculations?.salary_and_bonus?.toFixed(3) || 0
        ),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "2",
        "Phúc lợi y tế",
        "",
        "",
        "",
        "",
        Number(
          ecus_input?.direct_rvc_calculations?.medical_benefits?.toFixed(3) || 0
        ),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "",
        "Tổng II.",
        "",
        "",
        "",
        "",
        Number(ecus_input?.direct_rvc_calculations?.total_ii?.toFixed(3) || 0),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "III",
        "Chi phí phân bổ trực tiếp:",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "1",
        "Phí thuê nhà xưởng",
        "",
        "",
        "",
        "",
        Number(
          ecus_input?.direct_rvc_calculations?.factory_rental_fee?.toFixed(3) ||
            0
        ),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "2",
        "Phí khấu hao phương tiện VT, máy móc, nhà xưởng, chi phí DV thuê ngoài, chi phí CCDC, CP nhân viên phân xưởng. …",
        "",
        "",
        "",
        "",
        Number(
          ecus_input?.direct_rvc_calculations?.depreciation_cost?.toFixed(3) ||
            0
        ),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "",
        "Tổng III.",
        "",
        "",
        "",
        "",
        Number(ecus_input?.direct_rvc_calculations?.total_iii?.toFixed(3) || 0),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      ["IV", "Chi phí xuất xưởng", "", "", "", "", "", "", "", "", "", "", ""],
      [
        "",
        "(Tổng I+II+III):",
        "",
        "",
        "",
        "",
        Number(
          ecus_input?.direct_rvc_calculations?.factory_outgoing_cost?.toFixed(
            3
          ) || 0
        ),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "V",
        "Lợi nhuận",
        "",
        "",
        "",
        "",
        Number(ecus_input?.direct_rvc_calculations?.profit?.toFixed(3) || 0),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      ["VI", "Giá xuất xưởng", "", "", "", "", "", "", "", "", "", "", ""],
      [
        "",
        "(Tổng IV+V):",
        "",
        "",
        "",
        "",
        Number(
          ecus_input?.direct_rvc_calculations?.factory_outgoing_price?.toFixed(
            3
          ) || 0
        ),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      ["VII", "Các chi phí khác", "", "", "", "", "", "", "", "", "", "", ""],
      [
        "",
        "(chi phí vận chuyển,…)",
        "",
        "",
        "",
        "",
        Number(
          ecus_input?.direct_rvc_calculations?.other_costs?.toFixed(3) || 0
        ),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "VIII",
        "Trị giá FOB (VI+VII)",
        "",
        "",
        "",
        "",
        Number(
          ecus_input?.direct_rvc_calculations?.total_FOB_value?.toFixed(3) || 0
        ),
        "",
        "",
        "",
        "",
        "",
        "",
      ],

      ...formular(
        recalculate_args?.form_criteria,
        recalculate_args?.form_type,
        !!recalculate_args?.progress_price
      ),
      ...(deminimisData?.is_deminimis
        ? [
            [
              "",
              "Tỷ lệ De Minimis = ",
              `${
                deminimisData?.is_weight
                  ? "Tổng trọng lượng của các nguyên liệu không có xuất xứ và không đáp ứng tiêu chí CTC"
                  : "Tổng trị giá của các nguyên liệu không có xuất xứ và không đáp ứng tiêu chí CTC"
              }`,
              "",
              "",
              "",
              "",
              "",
              "x 100%",
              "",
              "",
              "",
              "",
            ],
            [
              "",
              "",
              `${
                deminimisData?.is_weight
                  ? "Tổng trọng lượng của thành phẩm"
                  : " Trị giá FOB của thành phẩm"
              }`,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          ]
        : []),

      ["Kết luận: Hàng hóa đáp ứng tiêu chí “RVC40” "],
      [
        "Công ty cam kết số liệu khai trên là đúng và xin chịu trách nhiệm trước pháp luật về thông tin, số liệu đã khai.",
      ],
    ],
    [
      company_info?.company_address,
      company_info?.company_name,
      company_info?.company_tax,
      ecus_input?.ecus_cd_date,
      ecus_input?.ecus_cd_number,
      ecus_input?.ecus_hs,
      ecus_input?.ecus_item_name,
      ecus_input?.ecus_total_quantity,
      ecus_input?.formula_nt_value,
      ecus_input?.formula_status,
      formular,
      itemMap,
      recalculate_args?.criteria_value,
      recalculate_args?.form_criteria,
      recalculate_args?.form_type,
      recalculate_args?.progress_price,
      recalculate_args?.total_non_origin_price,
      recalculate_args?.total_origin_price,
    ]
  );

  const mergeCellsData = useMemo(() => {
    return [...itemMap]
      .map((item, indx) => {
        return !!item[item.length - 1] && !!item[item.length - 2]
          ? null
          : {
              row: 14 + indx,
              col: 11,
              rowspan: 1,
              colspan: 2,
            };
      })
      .filter((item) => !!item);
  }, [itemMap]);

  mergeCellsData;

  const mergeCells = useMemo(
    () => [
      { row: 0, col: 0, rowspan: 1, colspan: 13 },
      { row: 1, col: 0, rowspan: 1, colspan: 13 },
      { row: 2, col: 0, rowspan: 1, colspan: 13 },
      { row: 3, col: 10, rowspan: 1, colspan: 3 },
      { row: 4, col: 10, rowspan: 1, colspan: 3 },
      { row: 5, col: 10, rowspan: 1, colspan: 3 },
      { row: 6, col: 10, rowspan: 1, colspan: 3 },
      { row: 7, col: 10, rowspan: 1, colspan: 3 },
      { row: 9, col: 0, rowspan: 1, colspan: 13 },
      { row: 10, col: 0, rowspan: 3, colspan: 1 },
      { row: 10, col: 1, rowspan: 3, colspan: 1 },
      { row: 10, col: 2, rowspan: 3, colspan: 1 },
      { row: 10, col: 3, rowspan: 3, colspan: 1 },
      { row: 10, col: 4, rowspan: 3, colspan: 1 },
      { row: 10, col: 5, rowspan: 1, colspan: 3 },
      { row: 10, col: 8, rowspan: 3, colspan: 1 },
      { row: 10, col: 9, rowspan: 2, colspan: 2 },
      { row: 10, col: 11, rowspan: 2, colspan: 2 },
      { row: 11, col: 5, rowspan: 2, colspan: 1 },
      { row: 11, col: 6, rowspan: 1, colspan: 2 },
      ...mergeCellsData,
      ...mergeFormular(
        recalculate_args?.form_criteria,
        recalculate_args?.form_type,
        !!recalculate_args?.progress_price
      ),
      {
        row: data.length - 1,
        col: 0,
        rowspan: 1,
        colspan: 13,
      },
      {
        row: data.length - 2,
        col: 0,
        rowspan: 1,
        colspan: 13,
      },

      deminimisData?.is_deminimis && {
        row: data.length - 4,
        col: 2,
        rowspan: 1,
        colspan: 6,
      },
      deminimisData?.is_deminimis && {
        row: data.length - 3,
        col: 2,
        rowspan: 1,
        colspan: 6,
      },
      deminimisData?.is_deminimis && {
        row: data.length - 4,
        col: 1,
        rowspan: 2,
        colspan: 1,
      },
      deminimisData?.is_deminimis && {
        row: data.length - 4,
        col: 8,
        rowspan: 2,
        colspan: 1,
      },
    ],
    [
      mergeCellsData,
      data.length,
      mergeFormular,
      recalculate_args?.form_criteria,
      recalculate_args?.form_type,
      recalculate_args?.progress_price,
      deminimisData?.is_deminimis,
    ]
  );

  const cellMeta = [
    { row: 0, col: 0, className: "htCenter htMiddle htBold" },
    { row: 1, col: 0, className: "htCenter htMiddle htBold" },
    { row: 2, col: 0, className: "htCenter htMiddle htItalic" },
    { row: 10, col: 0, className: "htCenter htMiddle" },
    { row: 10, col: 1, className: "htCenter htMiddle" },
    { row: 10, col: 2, className: "htCenter htMiddle" },
    { row: 10, col: 3, className: "htCenter htMiddle" },
    { row: 10, col: 4, className: "htCenter htMiddle" },
    { row: 10, col: 5, className: "htCenter htMiddle" },
    { row: 10, col: 8, className: "htCenter htMiddle" },
    { row: 10, col: 9, className: "htCenter htMiddle" },
    { row: 10, col: 11, className: "htCenter htMiddle" },
    { row: 10, col: 12, className: "htCenter htMiddle" },
    { row: 10, col: 13, className: "htCenter htMiddle" },

    { row: 11, col: 6, className: "htCenter htMiddle" },
    { row: 11, col: 5, className: "htCenter htMiddle" },

    { row: 12, col: 9, className: "htCenter htMiddle" },
    { row: 12, col: 10, className: "htCenter htMiddle" },
    { row: 12, col: 11, className: "htCenter htMiddle" },
    { row: 12, col: 12, className: "htCenter htMiddle" },

    { row: 13, col: 0, className: "htCenter htMiddle" },
    { row: 13, col: 1, className: "htCenter htMiddle" },
    { row: 13, col: 2, className: "htCenter htMiddle" },
    { row: 13, col: 3, className: "htCenter htMiddle" },
    { row: 13, col: 4, className: "htCenter htMiddle" },
    { row: 13, col: 5, className: "htCenter htMiddle" },
    { row: 13, col: 6, className: "htCenter htMiddle" },
    { row: 13, col: 7, className: "htCenter htMiddle" },
    { row: 13, col: 8, className: "htCenter htMiddle" },
    { row: 13, col: 9, className: "htCenter htMiddle" },
    { row: 13, col: 10, className: "htCenter htMiddle" },
    { row: 13, col: 11, className: "htCenter htMiddle" },
    { row: 13, col: 12, className: "htCenter htMiddle" },
    { row: 13, col: 13, className: "htCenter htMiddle" },
    { row: 14, col: 14, className: "htCenter htMiddle" },

    { row: 14, col: 0, className: "htBold" },
    { row: 14, col: 1, className: "htBold" },

    { row: data?.length - 2, col: 0, className: "" },
    ...itemAlight,
    ...alighFormular(
      recalculate_args?.form_criteria,
      recalculate_args?.form_type,
      !!recalculate_args?.progress_price
    ),
    // { row: 15 + bangke_data?.length, col: 0, className: "htCenter htMiddle" },
    // { row: 15 + bangke_data?.length, col: 1, className: "htCenter htMiddle" },
    // { row: 15 + bangke_data?.length, col: 2, className: "htMiddle htCenter" },
    // { row: 15 + bangke_data?.length, col: 3, className: "htCenter htMiddle" },
    // { row: 16 + bangke_data?.length, col: 1, className: "htCenter" },

    deminimisData?.is_deminimis
      ? {
          row: data.length - 4,
          col: 1,
          className: "htCenter htMiddle",
        }
      : {},
    deminimisData?.is_deminimis
      ? {
          row: data.length - 4,
          col: 8,
          className: "htCenter htMiddle",
        }
      : {},
  ];

  const colWidths = useMemo(
    () => [200, 300, 100, 100, 100, 100, 100, 100, 100, 200, 200, 200, 200],
    []
  );

  const afterGetCellMeta = (row, col, cellProperties) => {
    cellMeta.forEach((meta) => {
      if (meta.row === row && meta.col === col) {
        cellProperties.className = meta.className;
      }
    });
  };
  const hotTableRef = useRef(null);

  useEffect(() => {
    const hotInstance = hotTableRef?.current?.hotInstance;

    if (hotInstance) {
      hotInstance.view._wt?.wtOverlays.updateMainScrollableElements();
    }
  }, [
    data,
    colWidths,
    mergeCells,
    customBorders,
    recalculate_args,
    styleTable,
  ]);

  return (
    <div
      style={{
        height: "fit-content",
        overflow: "auto",
      }}
    >
      {bangke_data?.length > 0 ? (
        <HotTable
          ref={hotTableRef}
          data={data}
          colWidths={colWidths}
          mergeCells={mergeCells}
          colHeaders
          rowHeaders
          //   stretchH="all"
          //   manualColumnResize
          //   width="100%"
          customBorders={customBorders(
            recalculate_args?.form_criteria,
            recalculate_args?.form_type,
            !!recalculate_args?.progress_price
          )}
          height={styleTable ? styleTable.height : 500}
          afterGetCellMeta={afterGetCellMeta}
          licenseKey="non-commercial-and-evaluation"
        />
      ) : (
        <div className="error-wrapper">
          <div className="empty-text">Empty Data</div>
          {message_error && (
            <div className="error-text">Detail: {message_error}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default BangkeRVCDirect;

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import IntlMessages from "@uni/utility/IntlMessages";
import { useIntl } from "react-intl";
import { AppRowContainer } from "@uni";
import { MinusCircleOutlined } from "@ant-design/icons";
import usePostBangkeDeminimis from "hooks/apis/bangke/usePostBangKeDeminimis";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";

const RVCDirectModal = ({ setDataRow, dataRow }, ref) => {
  const { messages } = useIntl();
  const { companyManagement } = useLayoutContext();
  const { setDeminimisData } = useGenerateContext();

  const [form] = Form.useForm();

  const [isVisible, setIsVisible] = useState(false);

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);
  const closeModal = useCallback(
    () => (setIsVisible(false), form.resetFields()),
    [setIsVisible]
  );
  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal]
  );

  const handleRVCDirect = () => {
    form.validateFields().then((values) => {
      try {
        const convertedData = Object.fromEntries(
          Object.entries(values).map(([key, value]) => [
            key,
            value ? parseFloat(value) : 0,
          ])
        );

        const newData = [...dataRow].map((item) => {
          if (item?.form_type === "RVC Direct") {
            return { ...item, direct_rvc_calculations: convertedData };
          }
          return item;
        });

        setDataRow(newData);
        setIsVisible(false);
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  return (
    <Modal
      open={isVisible}
      onCancel={closeModal}
      width={600}
      title="RVC Direct"
      onOk={handleRVCDirect}
      bodyStyle={{ height: "600px" }}
      okText={messages["common.confirm"].toString()}
    >
      <Form layout="horizontal" form={form}>
        <AppRowContainer style={{ position: "relative" }}>
          <Col md={8}>
            <Form.Item>Salary And Bonus</Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item name="salary_and_bonus">
              <Input min={0} type="number" placeholder="Salary And Bonus" />
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item>Medical Benefits</Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item name="medical_benefits">
              <Input min={0} type="number" placeholder="Medical Benefits" />
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item>Factory Rental Fee</Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item name="factory_rental_fee">
              <Input min={0} type="number" placeholder="Factory Rental Fee" />
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item>Depreciation Cost</Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item name="depreciation_cost">
              <Input min={0} type="number" placeholder="Depreciation Cost" />
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item>Factory Outgoing Cost</Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item name="factory_outgoing_cost">
              <Input
                min={0}
                type="number"
                placeholder="Factory Outgoing Cost"
              />
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item>Profit</Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item name="profit">
              <Input min={0} type="number" placeholder="Profit" />
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item>Other Costs</Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item name="other_costs">
              <Input min={0} type="number" placeholder="Other Costs" />
            </Form.Item>
          </Col>
        </AppRowContainer>
      </Form>
    </Modal>
  );
};

export default forwardRef(RVCDirectModal);

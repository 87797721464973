import { URL_API } from "@uni/services/endpoint";
import jwtAxios from "@uni/services/auth/jwt-auth/jwt-api";
import { useQuery } from "react-query";

const getCompaniesProfile = (id) =>
  jwtAxios.get(`${URL_API.COMPANY.GET_USER_COMPANY}${id}/`);

const useGetCompaniesProfile = ({ id, isEnabled }) =>
  useQuery(
    [URL_API.COMPANY.GET_USER_COMPANY, id],
    () => getCompaniesProfile(id),
    {
      enabled: isEnabled,
    }
  );

export default useGetCompaniesProfile;
